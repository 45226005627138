// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cases-2-cents-js": () => import("./../../../src/pages/cases/2cents.js" /* webpackChunkName: "component---src-pages-cases-2-cents-js" */),
  "component---src-pages-cases-cartovera-js": () => import("./../../../src/pages/cases/cartovera.js" /* webpackChunkName: "component---src-pages-cases-cartovera-js" */),
  "component---src-pages-cases-chernomorsk-js": () => import("./../../../src/pages/cases/chernomorsk.js" /* webpackChunkName: "component---src-pages-cases-chernomorsk-js" */),
  "component---src-pages-cases-city-portal-js": () => import("./../../../src/pages/cases/city-portal.js" /* webpackChunkName: "component---src-pages-cases-city-portal-js" */),
  "component---src-pages-cases-crm-sap-integration-js": () => import("./../../../src/pages/cases/crm-sap-integration.js" /* webpackChunkName: "component---src-pages-cases-crm-sap-integration-js" */),
  "component---src-pages-cases-design-js": () => import("./../../../src/pages/cases/design.js" /* webpackChunkName: "component---src-pages-cases-design-js" */),
  "component---src-pages-cases-diasoft-js": () => import("./../../../src/pages/cases/diasoft.js" /* webpackChunkName: "component---src-pages-cases-diasoft-js" */),
  "component---src-pages-cases-fcteam-js": () => import("./../../../src/pages/cases/fcteam.js" /* webpackChunkName: "component---src-pages-cases-fcteam-js" */),
  "component---src-pages-cases-gud-job-js": () => import("./../../../src/pages/cases/gud-job.js" /* webpackChunkName: "component---src-pages-cases-gud-job-js" */),
  "component---src-pages-cases-ilich-realty-js": () => import("./../../../src/pages/cases/ilich-realty.js" /* webpackChunkName: "component---src-pages-cases-ilich-realty-js" */),
  "component---src-pages-cases-ingo-js": () => import("./../../../src/pages/cases/ingo.js" /* webpackChunkName: "component---src-pages-cases-ingo-js" */),
  "component---src-pages-cases-iot-js": () => import("./../../../src/pages/cases/iot.js" /* webpackChunkName: "component---src-pages-cases-iot-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-cases-karpatska-chaika-js": () => import("./../../../src/pages/cases/karpatska-chaika.js" /* webpackChunkName: "component---src-pages-cases-karpatska-chaika-js" */),
  "component---src-pages-cases-pickup-sport-js": () => import("./../../../src/pages/cases/pickup-sport.js" /* webpackChunkName: "component---src-pages-cases-pickup-sport-js" */),
  "component---src-pages-cases-rzd-roads-js": () => import("./../../../src/pages/cases/RZD-roads.js" /* webpackChunkName: "component---src-pages-cases-rzd-roads-js" */),
  "component---src-pages-cases-service-doc-js": () => import("./../../../src/pages/cases/service-doc.js" /* webpackChunkName: "component---src-pages-cases-service-doc-js" */),
  "component---src-pages-cases-social-app-js": () => import("./../../../src/pages/cases/social-app.js" /* webpackChunkName: "component---src-pages-cases-social-app-js" */),
  "component---src-pages-cases-utility-management-software-js": () => import("./../../../src/pages/cases/utility-management-software.js" /* webpackChunkName: "component---src-pages-cases-utility-management-software-js" */),
  "component---src-pages-cases-video-conference-js": () => import("./../../../src/pages/cases/video-conference.js" /* webpackChunkName: "component---src-pages-cases-video-conference-js" */),
  "component---src-pages-cases-videoconftool-js": () => import("./../../../src/pages/cases/videoconftool.js" /* webpackChunkName: "component---src-pages-cases-videoconftool-js" */),
  "component---src-pages-cases-webrtc-js": () => import("./../../../src/pages/cases/webrtc.js" /* webpackChunkName: "component---src-pages-cases-webrtc-js" */),
  "component---src-pages-cases-wikigrads-js": () => import("./../../../src/pages/cases/wikigrads.js" /* webpackChunkName: "component---src-pages-cases-wikigrads-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-estimate-js": () => import("./../../../src/pages/estimate.js" /* webpackChunkName: "component---src-pages-estimate-js" */),
  "component---src-pages-expertise-cloud-solutions-js": () => import("./../../../src/pages/expertise/cloud-solutions.js" /* webpackChunkName: "component---src-pages-expertise-cloud-solutions-js" */),
  "component---src-pages-expertise-cross-platform-js": () => import("./../../../src/pages/expertise/cross-platform.js" /* webpackChunkName: "component---src-pages-expertise-cross-platform-js" */),
  "component---src-pages-expertise-internet-of-things-js": () => import("./../../../src/pages/expertise/internet-of-things.js" /* webpackChunkName: "component---src-pages-expertise-internet-of-things-js" */),
  "component---src-pages-expertise-js": () => import("./../../../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-expertise-medical-app-development-services-js": () => import("./../../../src/pages/expertise/medical-app-development-services.js" /* webpackChunkName: "component---src-pages-expertise-medical-app-development-services-js" */),
  "component---src-pages-expertise-rtc-js": () => import("./../../../src/pages/expertise/rtc.js" /* webpackChunkName: "component---src-pages-expertise-rtc-js" */),
  "component---src-pages-expertise-uiuxdesign-js": () => import("./../../../src/pages/expertise/uiuxdesign.js" /* webpackChunkName: "component---src-pages-expertise-uiuxdesign-js" */),
  "component---src-pages-how-we-work-js": () => import("./../../../src/pages/how-we-work.js" /* webpackChunkName: "component---src-pages-how-we-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-dedicatedteam-js": () => import("./../../../src/pages/services/dedicatedteam.js" /* webpackChunkName: "component---src-pages-services-dedicatedteam-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-mobile-js": () => import("./../../../src/pages/services/mobile.js" /* webpackChunkName: "component---src-pages-services-mobile-js" */),
  "component---src-pages-services-qa-js": () => import("./../../../src/pages/services/qa.js" /* webpackChunkName: "component---src-pages-services-qa-js" */),
  "component---src-pages-services-startups-js": () => import("./../../../src/pages/services/startups.js" /* webpackChunkName: "component---src-pages-services-startups-js" */),
  "component---src-pages-services-uiuxdesign-js": () => import("./../../../src/pages/services/uiuxdesign.js" /* webpackChunkName: "component---src-pages-services-uiuxdesign-js" */),
  "component---src-pages-services-web-js": () => import("./../../../src/pages/services/web.js" /* webpackChunkName: "component---src-pages-services-web-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */)
}

