import "./src/style/style.scss"

// Option for disabling Gatsby from keeping scroll position on certain routes. Just add route in 'routes' array

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {

  const { pathname } = location;
  const routes = ['/estimate/', '/estimate']

  if(routes.indexOf(pathname) !== -1) {
    const node = document.getElementById("name")
    node.scrollIntoView({ block: "center", behavior: "smooth" })
    return false;
  }

  return true;
};
